<template>
  <div>
    <div v-show="this.$route.meta.showRole">
      <div class="condition">
        <div class="condition_item1">
          <el-input
            class="condition_item1_input"
            placeholder="请输入设备编码或关键字"
            v-model.trim="keywordsStr"
          ></el-input>
          <select-tree
            v-if="loading.tree"
            style="width: 168px; margin-left: 10px"
            ref="selectTree"
            :props="props"
            placeholder="请选择组织"
            :options="options"
            :value="deptId"
            :clearable="false"
            :accordion="true"
            @getValue="getValue($event)"
          />
          <el-select
            style="width: 168px; margin-left: 10px"
            clearable
            v-model="customerIds"
            placeholder="请选择客户"
          >
            <el-option
              v-for="item in clientele"
              :key="item.customerId"
              :label="item.customerName"
              :value="item.customerId"
            >
            </el-option>
          </el-select>
          <span id="disposeState">
            <el-select clearable v-model="onlineStatus" placeholder="在线状态">
              <el-option
                v-for="item in disposeState"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span id="faultState">
            <el-select clearable v-model="status" placeholder="设备状态">
              <el-option
                v-for="item in faultState"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span id="timeSpan">
            <el-select clearable v-model="timeSpan" placeholder="资产年限">
              <el-option
                v-for="item in timeSpanItem"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span id="communicationModel">
            <el-select
              clearable
              v-model="communicationModel"
              placeholder="通信方式"
            >
              <el-option
                v-for="item in communicationModelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
        </div>
        <div class="condition_item1">
          <span style="margin-right: 10px">生产时间</span>
          <el-date-picker
            prefix-icon="el-icon-date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="startCreateTime"
            type="datetime"
            placeholder="选择开始日期时间"
          >
          </el-date-picker>
          <span style="padding: 4px">-</span>
          <el-date-picker
            prefix-icon="el-icon-date"
            v-model="endCreateTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择结束日期时间"
          >
          </el-date-picker>
          <span style="margin-right: 10px; margin-left: 10px">投放时间</span>
          <el-date-picker
            prefix-icon="el-icon-date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="startTime"
            type="datetime"
            placeholder="选择开始日期时间"
          >
          </el-date-picker>
          <span style="padding: 4px">-</span>
          <el-date-picker
            prefix-icon="el-icon-date"
            v-model="endTimes"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择结束日期时间"
          >
          </el-date-picker>
        </div>
        <div>
          <el-button
            @click="Query(false)"
            style="margin-left: 10px"
            class="buttons query"
            >查询</el-button
          >
          <!--                <el-button class="buttons" icon="el-icon-plus">添加</el-button>-->
          <el-button
            @click="dialogVisible = true"
            class="buttons import"
            icon="el-icon-download"
            >导入
          </el-button>
          <el-button class="import" icon="el-icon-upload2" @click="importDatas"
            >导出</el-button
          >
          <el-button @click="getDialogVisibles" v-if="func.getRoot(7)">
            <svg-icon
              icon-class="ic_distribution_16x16"
              class-name="icon"
            ></svg-icon>
            批量分配组织
          </el-button>
          <el-button @click="alarmBatchReset" v-if="func.getRoot(48)">
            <svg-icon
              icon-class="ic_distribution_16x16"
              class-name="icon"
            ></svg-icon>
            告警批量重置
          </el-button>
          <el-button
            icon="el-icon-c-scale-to-original"
            @click="getDialogStatusVisible"
            v-if="func.getRoot(45)"
            >批量修改状态
          </el-button>
          <el-button
            icon="el-icon-setting"
            @click="allControl"
            v-if="func.getRoot(27)"
            >批量控制</el-button
          >
          <el-button
            icon="el-icon-delete"
            :disabled="this.multipleSelection.length == 0"
            v-if="func.getRoot(31)"
            @click="handleDelete"
            >批量删除</el-button
          >
          <el-button
            icon="el-icon-setting"
            @click="resetPosition"
            v-if="func.getRoot(49)"
            >批量重置基准定位</el-button
          >
        </div>
      </div>
      <div class="table">
        <el-table
          stripe
          :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-row-class-name="tableRowClassName"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="设备编码" width="120">
            <template slot-scope="scope"
              ><span @click="jumpDetails(scope.row)">
                {{ scope.row.deviceCode }}</span
              ></template
            >
          </el-table-column>
          <el-table-column prop="deviceFeature" label="设备型号" width="120">
            <template slot-scope="scope">
              {{ scope.row.deviceFeature }}
            </template>
          </el-table-column>
          <el-table-column
            prop="assetCode"
            label="资产编码"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span @click="jumpDetails(scope.row)">{{
                scope.row.assetCode
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="URL" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deviceRfId" label="RFID" show-overflow-tooltip>
            <template slot-scope="scope">
              <span @click="jumpDetails(scope.row)">{{
                scope.row.deviceRfId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceImei" label="IMEI" show-overflow-tooltip>
            <template slot-scope="scope">
              <span @click="jumpDetails(scope.row)">{{
                scope.row.deviceImei
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="在线状态" width="60" show-overflow-tooltip>
            <template slot-scope="scope">{{
              scope.row.onlineStatus == 1 ? "在线" : "离线"
            }}</template>
          </el-table-column>
          <el-table-column label="通信方式" width="60" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.communicationModel }}
            </template>
          </el-table-column>
          <el-table-column
            prop="customerName"
            label="客户"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="deptName"
            label="所属组织"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="putArea"
            label="投放区域"
            width="60"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="putAreaTime"
            label="投放时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.putAreaTime | formattingTime }}
            </template>
          </el-table-column>
          <el-table-column label="设备状态" width="60" show-overflow-tooltip>
            <template slot-scope="scope">{{
              deviceStatus(scope.row.status)
            }}</template>
          </el-table-column>

          <el-table-column
            prop="productTime"
            label="生产时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.productTime | formattingTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="assetYear"
            label="资产年限"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="isExpire"
            label="保修期状态"
            width="70"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.isExpire | isExpires }}
            </template>
          </el-table-column>
          <el-table-column
            prop="deviceType"
            label="资产类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="当前地址"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="assetCode"
            v-if="
              func.getRoot(
                dataList.rootList.find(el => el.name === '资产编辑').value
              )
            "
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="edit el-icon-edit"
                size="small"
                @click="edit(scope.row, true)"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="app-page-container">
        <span @click="first">首页</span>
        <el-pagination
          @current-change="handleCurrentChange"
          layout="pager"
          :hide-on-single-page="false"
          :page-size="12"
          :current-page.sync="currentPage"
          :total="recordCount"
        >
        </el-pagination>
        <span @click="last">尾页</span>
      </div>
      <el-dialog
        title="请选择上传文件"
        :visible.sync="dialogVisible"
        width="30%"
        @close="handleClose"
      >
        <el-upload
          class="upload-demo"
          accept=".xlsx,.xls"
          :show-file-list="true"
          :on-change="onChange"
          :http-request="httpRequest"
          action="url"
          :limit="1"
          :auto-upload="true"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="uploading">关闭</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="请选择分配组织"
        :visible.sync="dialogVisibles"
        width="30%"
        :before-close="handleCloses"
      >
        <select-tree
          v-if="loading.tree"
          style="width: 168px"
          ref="selectTree"
          :props="props"
          placeholder="请选择组织"
          :options="options"
          :value="allocationOrganization"
          :clearable="false"
          :accordion="true"
          @getValue="getAllocationOrganizationValue($event)"
        />
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="allocations(1)">确定</el-button>
          <el-button type="primary" @click="uploadings">关闭</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="请选择修改状态"
        :visible.sync="dialogStatusVisible"
        width="30%"
        :before-close="
          () => {
            dialogStatusVisible = false;
          }
        "
      >
        <el-select clearable v-model="changeStatus" placeholder="设备状态">
          <el-option
            v-for="item in faultState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="allocations(2)">确定</el-button>
          <el-button type="primary" @click="dialogStatusVisible = false"
            >关闭</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="设备控制"
        :visible.sync="dialogControlVisible"
        width="50%"
        :before-close="
          () => {
            dialogControlVisible = false;
            Query();
          }
        "
      >
        <div class="card state_control">
          <div class="device_status_setup">
            <el-form label-position="left" label-width="100px">
              <el-form-item
                :label="item.name"
                v-for="(item, index) in storage.deviceStatusSetUp"
                :key="index"
                v-show="item.type !== '1'"
              >
                <el-switch
                  v-if="item.type === '2'"
                  :active-value="1"
                  :inactive-value="0"
                  v-model="controlForm[item.prop]"
                  @change="handleControl(item)"
                />
                <template v-if="item.type === '3'">
                  <el-slider
                    class="slider"
                    :min="item.min"
                    :max="item.max"
                    :format-tooltip="item.formatTooltip"
                    v-model="controlForm[item.prop]"
                    :marks="item.marks || {}"
                  />
                  <el-button type="primary" @click="handleControl(item)"
                    >确定</el-button
                  >
                </template>
                <el-radio-group
                  v-if="item.type === '4'"
                  v-model="controlForm[item.prop]"
                  @change="handleControl(item)"
                >
                  <el-radio
                    v-for="sitem in item.options"
                    :key="sitem.value"
                    :label="sitem.value"
                    >{{ sitem.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogControlVisible = false"
            >关闭</el-button
          >
        </span>
      </el-dialog>
      <message-dialog
        ref="MessageDialog"
        :message="message"
        @reload="uploading"
      />
    </div>
    <!-- <router-view :editDatas="editData"></router-view> -->
  </div>
</template>

<script>
import {
  AssetManagement,
  deleteDeviceBatch,
  getDeviceTL,
  exportData,
  gitName,
  allocation,
  batchImportAsset,
  queryDeptAll,
  allcontent,
  allcontrols,
  allcontrol
} from "../../api/AssetManagement";
import MessageDialog from "@/componemts/MessageDialog";

export default {
  name: "index",
  components: { MessageDialog },
  data() {
    return {
      message: "",
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      },
      keywordsStr: "",
      options: [],
      loading: {
        tree: false
      },
      event: "",
      allocationOrganization: "",
      allocation: true,
      deptId: "",
      customerIds: "",
      clientele: [],
      token: localStorage.getItem("token"),
      userId: localStorage.getItem("userId"),
      coldChainAMHead: localStorage.getItem("coldChainAMHead"),
      pages: 1,
      url: window.baseUrl + "/clodchainAM/web/batch_import_asset",
      dialogVisible: false,
      dialogVisibles: false,
      dialogControlVisible: false,
      dialogStatusVisible: false,
      changeStatus: "1",
      exportExcelUrl:
        window.baseUrl +
        "/clodchainAM/web/batch_export_asset?isInWareHouse=1&" +
        "token=" +
        localStorage.getItem("token") +
        "&userId=" +
        localStorage.getItem("userId"),
      startTime: null,
      startCreateTime: null,
      endTimes: null,
      endCreateTime: null,
      fault: "",
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      organization: [],
      organizationName: "",
      disposeState: [
        {
          value: "1",
          label: "在线"
        },
        {
          value: "2",
          label: "离线"
        }
      ],
      onlineStatus: "",
      status: "",
      faultState: [
        {
          value: "1",
          label: "正常"
        },
        {
          value: "2",
          label: "维修"
        },
        {
          value: "3",
          label: "报废"
        }
      ],
      communicationModelList: [
        {
          value: "1",
          label: "2G"
        },
        {
          value: "2",
          label: "4G"
        }
      ],
      timeSpanItem: [],
      tableData: [],
      multipleSelection: [],
      timeSpan: "",
      communicationModel: "",
      deviceCode: "",
      time: null,
      editData: "",
      fileList: [],
      controlForm: {
        illumination: 0,
        longOff: 0,
        offTemValue: 0
      },
      storage: {
        jurisdiction: [],
        deviceStatusSetUp: [
          {
            typeCode: 2,
            prop: "illumination",
            name: "照明模式",
            type: "2"
          },
          {
            typeCode: 9,
            prop: "longOff",
            name: "远程开关设置",
            type: "2"
          },
          {
            typeCode: 3,
            prop: "offTemValue",
            name: "远程温度控制",
            type: "3",
            min: -50,
            max: 50,
            marks: {
              "-50": "-50°C",
              50: "50°C"
            },
            formatTooltip: value => {
              return value + "°C";
            }
          },
          {
            typeCode: 12,
            prop: "running_mode",
            name: "模式选择",
            type: "4",
            options: [
              {
                label: "正常",
                value: 0
              },
              {
                label: "节能",
                value: 1
              },
              {
                label: "休眠",
                value: 2
              },
              {
                label: "强制冷",
                value: 3
              }
            ]
          }
        ]
      }
    };
  },
  created() {
    this.Query();
    this.getorganization();
    this.getDeviceTLs();
    this.getClient();
  },
  filters: {
    deviceStatus(val) {
      if (val == 1) {
        return "正常";
      } else if (val == 2) {
        return "维修";
      } else if (val == 3) {
        return "报废";
      }
    },
    isExpires(val) {
      if (val == 1) {
        return "在保";
      } else if (val == 2) {
        return "不在保";
      }
    }
  },
  methods: {
    deviceStatus(val) {
      if (val == 1) {
        return "正常";
      } else if (val == 2) {
        return "维修";
      } else if (val == 3) {
        return "报废";
      }
    },
    //批量报废、分配组织、告警批量
    allocations(type) {
      if (!this.allocationOrganization && type == 1) {
        this.$message.error("请选择分配的组织");
        return false;
      }
      if (!this.changeStatus && type == 2) {
        this.$message.error("请选择状态");
        return false;
      }
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        let params = {
          deptId: "",
          deviceList: [],
          paramType: type
        };
        if (type == 1) {
          params.deptId = this.allocationOrganization;
        }
        if (type == 2) {
          params.status = this.changeStatus;
        }
        for (let i = 0; i < this.multipleSelection.length; i++) {
          params.deviceList.push(this.multipleSelection[i].deviceImei);
        }
        allocation(params).then(({ data }) => {
          if (data.resultCode == 0) {
            this.multipleSelection = [];
            this.$message.success("操作成功");
            this.dialogVisibles = false;
            this.dialogStatusVisible = false;
            this.Query();
          } else {
            this.$message.error(data.desc);
          }
        });
      } else {
        let params = this.getQueryForm();
        if (type == 1) {
          params.deptIdSet = this.allocationOrganization;
        }
        if (type == 2) {
          params.statusSet = this.changeStatus;
        }
        params.paramType = type;
        allcontent(params).then(({ data }) => {
          if (data.resultCode == 0) {
            this.$message.success("操作成功");
            this.dialogStatusVisible = false;
            this.dialogVisibles = false;
            this.Query();
          } else {
            this.$message.error(data.desc);
          }
        });
      }
    },
    //跳转设备详情页
    jumpDetails(data) {
      if (data.deviceRfId) {
        this.$router.push({
          name: "详情",
          params: {
            deviceRfId: data.deviceRfId
          }
        });
      }
    },
    //获取客户
    getClient() {
      gitName({}).then(res => {
        this.clientele = res.data.data;
      });
    },
    //下发控制命令
    control() {},
    handleControl(item) {
      const reqList = [];
      if (this.controlForm[item.prop] !== "") {
        reqList.push({
          typeCode: item.typeCode,
          name: item.name,
          value: this.controlForm[item.prop]
        });
      }
      if (this.multipleSelection == "") {
        let data = this.getQueryForm();
        allcontrols({
          ...data,
          reqList
        }).then(({ data }) => {
          if (data.resultCode == 0) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(data.desc);
          }
        });
      } else {
        const list = this.multipleSelection.map(item => {
          return item.deviceImei;
        });
        allcontrol({
          reqList: reqList,
          deviceImeiList: list
        }).then(({ data }) => {
          if (data.resultCode == 0) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(data.desc);
          }
        });
      }
    },
    //批量删除
    handleDelete() {
      const list = this.multipleSelection.map(item => {
        return item.deviceImei;
      });
      const that = this;
      function del() {
        deleteDeviceBatch({
          deviceImeiList: list
        }).then(({ data }) => {
          if (data.resultCode == 0) {
            that.$message.success("操作成功");
            that.Query(false);
          } else {
            this.$message.error(data.desc);
          }
        });
      }
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        del();
      });
    },
    resetPosition() {
      this.$confirm(`确认批量重置定位？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning"
      }).then(() => {
        this.allocations(4);
      });
    },
    // 全部批量进行控制
    allControl() {
      console.log(this.multipleSelection, "this.multipleSelection");
      if (this.multipleSelection != "") {
        this.dialogControlVisible = true;
      } else {
        this.$confirm(`确认批量控制全部的设备？`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "warning"
        }).then(() => {
          // this.getorganization();
          if (this.organization.length >= 500) {
            this.$message.error("批量控制最大500条");
            return;
          }
          this.dialogControlVisible = true;
        });
      }
    },
    //批量修改状态
    getDialogStatusVisible() {
      this.dialogStatusVisible = true;
      console.log(this.multipleSelection, "this.multipleSelection");
    },
    // 全部批量分配组织
    getDialogVisibles() {
      console.log(this.multipleSelection, "this.multipleSelection");
      if (this.multipleSelection != "") {
        this.dialogVisibles = true;
      } else {
        this.$confirm(`确认全部分配设备？`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "warning"
        }).then(() => {
          //this.getorganization();
          if (this.organization.length >= 2000) {
            this.$message.error("批量分配组织最大2000条");
            return;
          }
          this.dialogVisibles = true;
          this.loading.tree = true;
        });
      }
    },
    // 告警批量重置
    alarmBatchReset() {
      this.$confirm(`确认批量重置告警？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning"
      }).then(() => {
        this.allocations(3);
      });
    },
    //导出
    importDatas() {
      this.$store.commit("SET_LOADING", true);
      let ids = [];
      if (this.keywordsStr !== "") {
        ids = this.keywordsStr
          .replace(/\s*/g, "")
          .split(/[\uff0c|,]/)
          .filter(item => item !== "")
          .map(item => {
            return item.replace(/\s*/g, "");
          });
      }
      if (this.multipleSelection.length !== 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].deviceImei);
        }
      }
      let data = {
        isInWareHouse: 1,
        customerId: this.customerIds,
        keywordsStr: ids.join(","),
        deviceCode: this.deviceCode,
        deptId: this.organizationName, //部门ID
        status: this.status,
        onlineStatus: this.onlineStatus,
        assetYear: this.timeSpan,
        communicationModel: this.communicationModel,
        startCreateTime: this.startCreateTime,
        endCreateTime: this.endCreateTime,
        startPutAreaTime: this.startTime,
        endPutAreaTime: this.endTimes,
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId")
      };
      exportData(data).then(res => {
        const dt = new Date();
        const y = dt.getFullYear();
        const m = (dt.getMonth() + 1 + "").padStart(2, "0");
        const d = (dt.getDate() + "").padStart(2, "0");
        const hh = (dt.getHours() + "").padStart(2, "0");
        const mm = (dt.getMinutes() + "").padStart(2, "0");
        const ss = (dt.getSeconds() + "").padStart(2, "0");
        let date = y + m + d + hh + mm + ss;
        const content = res.data; //返回的内容
        let _this = this;
        if (res.data.type !== "application/vnd.ms-excel") {
          //二进制转字符串
          let reader = new FileReader();
          reader.addEventListener("loadend", function(e) {
            // 输出字符串 转化为json对象
            let str = JSON.parse(e.target.result);
            _this.$message({
              showClose: true,
              message: str.desc,
              type: "error"
            });
            if (str.resultCode == 2000) {
              _this.$router.push("/login");
            }
          });
          reader.readAsText(content);
          this.$store.commit("SET_LOADING", false);
        } else {
          const fileName = "资产信息列表" + date.toString() + ".xlsx"; //下载文件名
          //处理下载流
          const blob = new Blob([content]); //创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          const url = window.URL.createObjectURL(blob); //URL.createObjectURL(object)表示生成一个File对象或Blob对象
          let dom = document.createElement("a"); //设置一个隐藏的a标签，href为输出流，设置download
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName); //指示浏览器下载url,而不是导航到它；因此将提示用户将其保存为本地文件
          document.body.appendChild(dom);
          dom.click();
          this.$store.commit("SET_LOADING", false);
        }
      });
    },
    httpRequest(params) {
      const form = new FormData();
      form.append("file", params.file);
      form.append("token", this.token);
      form.append("userId", this.userId);
      const config = {
        data: form
      };
      console.log(config);
      batchImportAsset(config).then(({ data }) => {
        if (data.resultCode === 0) {
          params.onSuccess();
          this.message = "导入成功";
          this.$refs.MessageDialog.open();
        } else {
          params.onError();
          this.message = `导入失败，${data.data.failMessage}`;
          this.$refs.MessageDialog.open();
        }
      });
    },
    onChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    //编辑
    edit(data) {
      // this.editData = data;
      this.$router.push({
        path: "/dashboard/assetAdmin/edit",
        query: { ...data }
      });
    },
    //获取设备年限
    getDeviceTLs() {
      this.timeSpanItem = [];
      getDeviceTL({}).then(res => {
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i] != null) {
            this.timeSpanItem.push({
              value: data[i],
              label: data[i]
            });
          }
        }
      });
    },
    getValue(value) {
      this.organizationName = value;
    },
    getAllocationOrganizationValue(value) {
      this.allocationOrganization = value;
    },
    //获取组织
    // getorganization() {
    //   const config = {
    //     params: {
    //       userId: localStorage.getItem("userId"),
    //     },
    //   };
    //   queryDeptAll(config).then((res) => {
    //     this.organization = res.data.data;
    //   });
    // },
    //上传
    uploading() {
      this.dialogVisible = false;
      this.Query(false);
    },

    uploadings() {
      this.dialogVisibles = false;
    },
    //弹框
    handleClose(done) {
      this.fileList = [];
      this.Query(false);
      done();
    },
    handleCloses(done) {
      done();
    },
    //查询
    getQueryForm() {
      return {
        limit: 12,
        customerId: this.customerIds,
        curPage: this.pages,
        userId: localStorage.getItem("userId"),
        deviceCode: this.deviceCode,
        deptId: this.organizationName, //部门ID
        status: this.status,
        onlineStatus: this.onlineStatus,
        isInWareHouse: 1,
        assetYear: this.timeSpan,
        communicationModel: this.communicationModel,
        customerName: "",
        keywordsStr: this.keywordsStr
          .replace(/\s*/g, "")
          .split(/[\uff0c|,]/)
          .filter(item => item !== "")
          .map(item => {
            return item.replace(/\s*/g, "");
          })
          .join(","),
        startCreateTime: this.startCreateTime,
        endCreateTime: this.endCreateTime,
        startPutAreaTime: this.startTime,
        endPutAreaTime: this.endTimes
      };
    },
    getorganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.options.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.options.push(value);
          }
        });
        this.loading.tree = true;
      });
    },
    Query(type) {
      console.log(type);
      if (!type) {
        this.currentPage = 1;
        this.pages = 1;
      }
      let data = this.getQueryForm();
      AssetManagement(data).then(res => {
        this.recordCount = res.data.data.total;
        this.pages = res.data.data.pages;
        this.tableData = res.data.data.records;
      });
      this.loading.tree = true;
    },
    //分页首页
    first() {
      this.tableData = [];
      this.currentPage = 1;
      this.pages = 1;
      this.Query(true);
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.Query(true);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.Query(true);
      // console.log(`当前页: ${val}`);
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = val;
      this.allocation = false;
      if (this.multipleSelection.length == 0) {
        this.allocation = true;
      }
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex == 0) return "headerStyle";
    }
  }
};
</script>

<style scoped>
.table span {
  cursor: pointer;
}

.edit {
  font-size: 14px;
  color: #6e7790;
  margin-right: 10px;
}

.edit:hover {
  color: #0cc;
}

.icon {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  color: #606266;
}

/*分页样式*/
.app-page-container {
  margin-top: 20px;
}

.app-page-container .el-pagination {
  display: inline-block;
}

.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}

.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}

.condition_item1 {
  /*width: 840px;*/
  margin-bottom: 20px;
}

.condition_item1_input {
  width: 246px;
}

/deep/ el-table >>> .headerStyle th {
  background: #8c939d;
}

/*#organization{*/
/*    width: 168px !important;*/
/*}*/
/deep/ #organization .el-select {
  display: inline-block;
  position: relative;
  width: 168px;
  margin-left: 14px;
}

/deep/ #disposeState .el-select {
  display: inline-block;
  position: relative;
  width: 110px;
  margin-left: 14px;
}

/deep/ #faultState .el-select {
  display: inline-block;
  position: relative;
  width: 110px;
  margin-left: 14px;
}

/deep/ #timeSpan .el-select {
  display: inline-block;
  position: relative;
  width: 110px;
  margin-left: 14px;
}
/deep/ #communicationModel .el-select {
  display: inline-block;
  position: relative;
  width: 110px;
  margin-left: 14px;
}
.buttons {
  width: 96px;
}

.table {
  margin-top: 20px;
}

/deep/ .el-table .cell {
  white-space: pre-line;
}

/*/deep/ .el-table td, .el-table th{*/
/*    padding: 0;*/
/*}*/
.paging {
  margin-top: 20px;
}

/deep/.el-slider__marks-text {
  word-break: normal;
}

.slider {
  width: 300px;
  padding-bottom: 20px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
</style>
