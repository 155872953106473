<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div
      class="url"
      id="target"
      style="overflow: hidden; text-overflow:ellipsis; white-space: nowrap;"
    >
      {{ message }}
    </div>
    <el-button
      type="primary"
      size="medium"
      class="btn"
      id="copyBtn"
      :plain="true"
      :data-clipboard-text="message"
      data-clipboard-action="copy"
      data-clipboard-target="#target"
      @click="copy"
    >
      复制
    </el-button>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Clipboard from "clipboard";
export default {
  name: "MessageDialog",
  props: {
    message: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    copy() {
      let clipboard = new Clipboard("#copyBtn");
      clipboard.on("success", function(e) {
        e.clearSelection();
      });
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.dialogVisible = false;
          this.$emit("reload");
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped></style>
